import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Descriptions, Row, Col, Card, Divider, Table } from 'antd';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useParams } from 'react-router-dom';
import { ProjectSettings } from '../components/ProjectSettings';
import { BASE_API_URL } from '../util/constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
});

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    async function fetchProject() {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/project/${id}`);
        const projectData = response.data;
        setProject(projectData);
      } catch (error) {
        console.error('Error fetching project:', error);
        setApiError(true);
      } finally {
        setLoading(false);
      }
    }

    async function fetchMetrics(timeframe = 72) {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/project/${id}/metrics`, {
          params: {
            timeframe: timeframe
          }
        });
        const metricsData = response.data;
        setMetrics(metricsData);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    }

    async function fetchPlugins() {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/project/${id}/plugins`);
        const pluginsData = response.data;
        setPlugins(pluginsData);
      } catch (error) {
        console.error('Error fetching plugins:', error);
      }
    }

    fetchProject();
    fetchMetrics(72);
    fetchPlugins();
  }, [id]);

  if (loading) return <p>Loading...</p>;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'plugin_name',
      key: 'plugin_name',
      filteredValue: null, // Ensure consistency by adding this even if no filter is used
    },
    {
      title: 'Version',
      dataIndex: 'plugin_version',
      key: 'plugin_version',
      filteredValue: null, // Ensure consistency
    },
    {
      title: 'Update Status',
      dataIndex: 'update_status',
      key: 'update_status',
      filteredValue: null, // Add this for consistency
      render: (text) => {
        let color = '';
        if (text === 'none') {
          color = 'green';
        } else {
          color = 'red';
        }
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: 'Update Version',
      dataIndex: 'update_version',
      key: 'update_version',
      filteredValue: null, // Add this for consistency
    },
  ];

  const storageUsed = project?.storage ? (project.storage / 1024).toFixed(2) : 0;

  const formatLineChartData = (label, dataKey, maxValue) => {
    return {
      labels: metrics.map(metric => new Date(metric.recorded_at).toLocaleTimeString()),
      datasets: [
        {
          label: `${label} Usage (%)`,
          data: metrics.map(metric => (metric[dataKey] / maxValue) * 100),
          fill: false,
          borderColor: context => {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            return value > 80 ? 'red' : 'lightgrey';
          },
          backgroundColor: 'transparent',
          tension: 0.1,
          segment: {
            borderColor: ctx => {
              const value = ctx.p1.parsed.y; // p1 is the segment's end point, p0 is the start point
              return value > 80 ? 'red' : 'lightgrey';
            }
          }
        },
      ]
    };
  };
  

  const appCpuChartData = formatLineChartData('App CPU', 'cpu_app', project?.production_max_cpu);
  const appMemoryChartData = formatLineChartData('App Memory', 'memory_app', project?.production_max_memory);
  const appDiskChartData = formatLineChartData('App Disk', 'disk_app', project?.storage);

  const dbCpuChartData = formatLineChartData('DB CPU', 'cpu_db', project?.production_max_cpu);
  const dbMemoryChartData = formatLineChartData('DB Memory', 'memory_db', project?.production_max_memory);
  const dbDiskChartData = formatLineChartData('DB Disk', 'disk_db', project?.storage);

  return (
    <div className="project-wrapper">
      
      {project ? <ProjectSettings project={project} /> : <p>Loading...</p>}
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Subscription Status" bordered>
            <Descriptions column={1}>
              <Descriptions.Item label="User Licenses">{project?.user_licenses}</Descriptions.Item>
              <Descriptions.Item label="Plan">{project?.plan}</Descriptions.Item>
              <Descriptions.Item label="Environments">{project?.environments}</Descriptions.Item>
              <Descriptions.Item label="Storage Used (GB)">{storageUsed}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Resources" bordered>
            <Descriptions column={1}>
              <Descriptions.Item label="Max CPU (Production)">{project?.production_max_cpu}</Descriptions.Item>
              <Descriptions.Item label="Max Memory (GB) (Production)">{(project?.production_max_memory / 1024).toFixed(2)}</Descriptions.Item>
              <Descriptions.Item label="Max Environments (Production)">{project?.production_max_environments}</Descriptions.Item>
              <Descriptions.Item label="Max CPU (Development)">{project?.development_max_cpu}</Descriptions.Item>
              <Descriptions.Item label="Max Memory (GB) (Development)">{(project?.development_max_memory / 1024).toFixed(2)}</Descriptions.Item>
              <Descriptions.Item label="Max Environments (Development)">{project?.development_max_environments}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Card title="App CPU Usage" bordered>
            <Line data={appCpuChartData} options={{ responsive: true }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="App Memory Usage" bordered>
            <Line data={appMemoryChartData} options={{ responsive: true }} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="App Disk Usage" bordered>
            <Line data={appDiskChartData} options={{ responsive: true }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="DB CPU Usage" bordered>
            <Line data={dbCpuChartData} options={{ responsive: true }} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="DB Memory Usage" bordered>
            <Line data={dbMemoryChartData} options={{ responsive: true }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="DB Disk Usage" bordered>
            <Line data={dbDiskChartData} options={{ responsive: true }} />
          </Card>
        </Col>
      </Row>
      <Divider />
      {apiError ? (
        <p>Error: Unable to fetch project details. Please check your connection or contact support.</p>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Card title="Plugins" bordered>
                <Table
                  columns={columns}
                  dataSource={plugins}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default ProjectDetail;
