import React, { useEffect, useState } from 'react';
import { Descriptions, Tooltip, Input, Select, Button, message, Modal, Row, Col, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { updateProjectSetting } from '../util/api';
import { SettingOutlined, ReloadOutlined, ApiOutlined } from '@ant-design/icons';
import { LinkOutlined } from '@ant-design/icons';
import { showAlert } from '../store/actions/alertActions';
import { useDispatch } from 'react-redux';
const { Option } = Select;

export const ProjectSettings = ({ project }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultDomain, setDefaultDomain] = useState('');
  const [hasApiEndpoint, setHasApiEndpoint] = useState('');
  const [wpPluginApiKey, setWpPluginApiKey] = useState('');

  useEffect(() => {
    if (project) {
      setLoading(false);
      setDefaultDomain(project.default_domain || '');
      setHasApiEndpoint(project.has_api_endpoint ? 'true' : 'false');
      setWpPluginApiKey(project.wp_plugin_api_key || '');
    }
  }, [project]);

  if (loading) return <p>Loading...</p>;

  const handleUpdateApiEndpoint = async () => {
    try {
      await updateProjectSetting('update-api-endpoint', { projectId: id, hasApiEndpoint });
      message.success('API Endpoint updated successfully.');
    } catch {
      message.error('Failed to update API Endpoint.');
    }
  };

  const handleUpdateDefaultDomain = async () => {
    try {
      await updateProjectSetting('update-default-domain', { projectId: id, defaultDomain });
      message.success('Default Domain updated successfully.');
    } catch {
      message.error('Failed to update Default Domain.');
    }
  };

  const handleUpdatePluginApiKey = async () => {
    try {
      await updateProjectSetting('update-plugin-api-key', { projectId: id, wpPluginApiKey });
      message.success('Plugin API Key updated successfully.');

      dispatch(showAlert({
        message: 'Plugin API Key has been added, it may take up to 10 minutes to start seeing metrics.',
        type: 'success'
      }));

    } catch {
      message.error('Failed to update Plugin API Key.');
    }
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleRefreshMetrics = async () => {
    // try {
    //   await updateProjectSetting('refresh-metrics', { projectId: id });
    //   message.success('Metrics refreshed successfully.');
    // } catch {
    //   message.error('Failed to refresh Metrics.');
    // }
  }

  const handleRefreshPlugins = async () => {
    // try {
    //   await updateProjectSetting('refresh-plugins', { projectId: id });
    //   message.success('Plugins refreshed successfully.');
    // } catch {
    //   message.error('Failed to refresh Plugins.');
    // }
  }

  return (
    <div className="project-wrapper">
      <Descriptions title="Project Details" bordered>
        <Descriptions.Item label="Title">{project?.project_title}</Descriptions.Item>
        <Descriptions.Item label="Region">{project?.project_region_label}</Descriptions.Item>
        <Descriptions.Item label="Status">{project?.status}</Descriptions.Item>
        <Descriptions.Item label="Created At">{new Date(project?.created_at).toLocaleString()}</Descriptions.Item>
        <Descriptions.Item label="Updated At">{new Date(project?.updated_at).toLocaleString()}</Descriptions.Item>
        <Descriptions.Item label="Support Tier">{project?.support_tier}</Descriptions.Item>
        <Descriptions.Item label="Actions">
        <Tooltip title="Settings">
          <Button icon={<SettingOutlined />} onClick={handleModalOpen} style={{ marginRight: 8 }} />
        </Tooltip>
        <Tooltip title="Refresh Metrics">
          <Button icon={<ReloadOutlined />} disabled onClick={handleRefreshMetrics} style={{ marginRight: 8 }} />
        </Tooltip>
        <Tooltip title="Refresh Plugins">
          <Button icon={<ApiOutlined />} disabled onClick={handleRefreshPlugins} />
        </Tooltip>
        <Tooltip title="Refresh Plugins">
          <Button style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} type="default" href={`https://console.platform.sh/maddenmedia-com/${id}`} target="_blank" rel="noopener noreferrer">
          Platform.sh <LinkOutlined />
          </Button>        
        </Tooltip>
        <Tooltip title="Refresh Plugins">
          <Button style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} type="primary" href={`https://${defaultDomain}`} target="_blank" rel="noopener noreferrer">
            Visit Website
          </Button>
        </Tooltip>
      </Descriptions.Item>
      </Descriptions>

      <Modal
        title="Project Settings"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item label="Default Domain">
            <Row gutter={8}>
              <Col span={18}>
                <Input
                  value={defaultDomain}
                  onChange={(e) => setDefaultDomain(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Button type="primary" onClick={handleUpdateDefaultDomain} block>
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="API Endpoint">
            <Row gutter={8}>
              <Col span={18}>
                <Select
                  value={hasApiEndpoint}
                  onChange={setHasApiEndpoint}
                  style={{ width: '100%' }}
                >
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              </Col>
              <Col span={6}>
                <Button type="primary" onClick={handleUpdateApiEndpoint} block>
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Item>

          {hasApiEndpoint === 'true' && (
            <Form.Item label="Plugin API Key">
              <Row gutter={8}>
                <Col span={18}>
                  <Input
                    value={wpPluginApiKey}
                    onChange={(e) => setWpPluginApiKey(e.target.value)}
                    type="password"
                  />
                </Col>
                <Col span={6}>
                  <Button type="primary" onClick={handleUpdatePluginApiKey} block>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectSettings;
