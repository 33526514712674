import React from 'react';
import { Divider, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from '../images/logo.js';

const Sidebar = () => {
  const items = [
    {
      key: 'logo',
      label: (
        <div className="logo-wrapper">
          <Logo />
        </div>
      ),
      type: 'group', // This ensures the logo is treated differently from other menu items
    },
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: 'search',
      icon: <SearchOutlined />,
      label: <Link to="/search">Search</Link>,
    },
    // Add more items here as needed
  ];

  return (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      items={items}
    />
  );
};

export default Sidebar;
