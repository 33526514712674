import { createReducer } from "@reduxjs/toolkit";
import { updateSites } from "../actions/sitesActions.js";

const initialState = {
    sites: []
}

const sitesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateSites, (state, action) => {
            state.sites = action.payload;
        })
});

export default sitesReducer;