import { createReducer } from "@reduxjs/toolkit";
import { showAlert, hideAlert } from "../actions/alertActions";

const initialState = {
    message: null,
    type: null,  // 'success', 'error', 'info', etc.
};

const alertReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(showAlert, (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
        })
        .addCase(hideAlert, (state) => {
            state.message = null;
            state.type = null;
        });
});

export default alertReducer;
