import React from "react";

const Logo = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 95.56 64.07">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M52.07,45.95c-.27.25-.95-.05-1.51-.66-.57-.61-.81-1.31-.54-1.56.27-.25.95.05,1.51.66.57.61.81,1.31.54,1.56Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M53.47,49.82c-.34.14-.87-.38-1.18-1.15-.31-.77-.29-1.51.04-1.65.34-.14.87.38,1.18,1.15.31.77.29,1.51-.04,1.65Z"
                            ></path>
                        </g>
                        <path
                            style={{ fill: "#3c3c69", strokeWidth: "0px" }}
                            d="M25.59,26.2c-1.91.19-3.64.86-4.54,1.26-3.91,1.72-6.28,4.46-7.47,6.08-.37.5-.8.94-1.5,1.5-.57.45-1.38,1.1-2.56,1.44-.46.14-2.03.59-3.26.04-2.41-1.09-3.54-6.09-1.2-8.57,1.44-1.53,3.62-1.51,4.21-1.49-.53-.13-3.95-.9-6.64,1.2-3.05,2.38-3.16,6.88-1.81,9.72,2.29,4.85,9.35,6.06,13.53,3.78,1.28-.7,2.14-1.65,2.7-2.41.09-.64.43-2.36,1.87-3.89,0,0,1.46-1.44,3.44-2,3.94-1.12,8.42.2,8.86,4.32,1.93.9,5.6.15,6.36-3.06,0,0-4.37-8.68-11.99-7.93Z"
                        ></path>
                        <g>
                            <path
                                style={{ fill: "#3c3c69", strokeWidth: "0px" }}
                                d="M76.67,40.38c1.13-.08,2.32.42,2.83,1.5.2-.62.35-1.25.44-1.9.78-5.81-4.03-12.99-11.96-13.78-7.61-.75-11.99,7.93-11.99,7.93.76,3.2,4.43,3.96,6.36,3.06.43-4.12,4.92-5.44,8.86-4.32,4.18,1.19,5.41,4.58,5.47,7.51Z"
                            ></path>
                            <path
                                style={{ fill: "#3e3c6b", strokeWidth: "0px" }}
                                d="M76.67,40.38c.38-.03.77.01,1.14.12.05-.48.07-.96.06-1.47-.19-5.44-5.89-10.61-13.46-7.83-3.67,1.35-5.54,3.55-6.48,5.62,1.42.86,3.24.93,4.42.38.43-4.12,4.92-5.44,8.86-4.32,4.18,1.19,5.41,4.58,5.47,7.51Z"
                            ></path>
                            <g>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M63.08,34.81c-.29-.34,0-1.06.64-1.59.64-.54,1.39-.69,1.68-.34.29.35,0,1.06-.64,1.59-.64.54-1.39.69-1.68.34Z"
                                ></path>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M63.58,34.77c-.17-.2.08-.67.55-1.06.47-.39.98-.55,1.14-.35.17.2-.08.67-.55,1.06-.47.39-.98.55-1.14.35Z"
                                ></path>
                            </g>
                            <g>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M74.04,34.36c.31-.28.98-.05,1.51.53.52.57.7,1.27.39,1.55-.31.28-.98.05-1.51-.53-.53-.57-.7-1.27-.39-1.55Z"
                                ></path>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M74.09,34.83c.18-.16.63.04,1.01.46.38.42.55.89.37,1.05-.18.16-.63-.05-1.01-.46-.38-.42-.55-.89-.37-1.05Z"
                                ></path>
                            </g>
                            <g>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M76.1,37.49c.4-.1.86.42,1.04,1.16.18.74,0,1.41-.4,1.51-.4.1-.86-.42-1.04-1.16-.18-.74,0-1.41.4-1.51Z"
                                ></path>
                                <path
                                    style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                    d="M75.93,37.91c.23-.05.52.34.65.87.13.54.05,1.02-.18,1.07-.23.05-.52-.34-.65-.87-.13-.54-.05-1.02.18-1.07Z"
                                ></path>
                            </g>
                        </g>
                        <path
                            style={{ fill: "#3e3c6b", strokeWidth: "0px" }}
                            d="M29.16,31.19c-6.43-2.36-11.35,1.03-12.94,5.42.2.77.43,1.47.83,2.14.44-2.47,1.88-4.92,5.31-5.89,3.94-1.12,8.42.2,8.86,4.32,1.18.55,3,.48,4.42-.38-.94-2.06-2.81-4.27-6.48-5.62Z"
                        ></path>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M27.47,32.74c.21-.4.98-.4,1.72,0,.74.39,1.16,1.03.95,1.43-.21.4-.98.4-1.72,0-.74-.39-1.16-1.03-.95-1.43Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M27.69,33.19c.12-.23.65-.18,1.19.11.54.29.87.7.75.93-.12.23-.65.18-1.19-.11-.54-.29-.87-.7-.75-.93Z"
                            ></path>
                        </g>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M23.39,32.5c.01-.45.7-.79,1.54-.77.83.03,1.5.41,1.48.86-.01.45-.7.79-1.54.77-.83-.03-1.5-.41-1.48-.86Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M23.8,32.81c0-.26.51-.45,1.12-.43.61.02,1.09.24,1.09.5,0,.26-.51.45-1.11.43-.61-.02-1.09-.24-1.09-.5Z"
                            ></path>
                        </g>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M17.27,37.83c-.4-.2-.45-.91-.11-1.58.34-.67.94-1.05,1.34-.84.4.2.45.91.11,1.58-.34.67-.94,1.05-1.34.84Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M17.69,37.68c-.23-.12-.21-.61.04-1.09.25-.49.64-.79.87-.67.23.12.21.61-.04,1.09-.25.49-.64.79-.87.67Z"
                            ></path>
                        </g>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M15.06,40.36c-.28-.21-.07-.84.46-1.42.53-.57,1.19-.87,1.47-.65.28.21.07.84-.46,1.42-.53.57-1.19.87-1.47.65Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M15.48,40.26c-.16-.12.03-.56.41-.97.39-.42.83-.66.99-.54.16.12-.03.56-.41.97-.39.42-.83.66-.99.54Z"
                            ></path>
                        </g>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M19.11,35.72c-.28.24-.94-.07-1.49-.7-.55-.63-.77-1.33-.5-1.57.28-.24.94.07,1.49.7.55.63.77,1.33.5,1.57Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M21.87,39.03c-.28.23-.94-.11-1.47-.76-.53-.65-.72-1.36-.44-1.59.28-.23.94.11,1.47.76.53.65.72,1.36.44,1.59Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M24.82,42.12c-.26.26-.95-.02-1.53-.61-.59-.59-.85-1.28-.59-1.54.26-.26.95.02,1.53.61.59.59.85,1.28.59,1.54Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M72.95,34.41c.29.22.12.94-.39,1.6-.51.66-1.15,1.02-1.44.8-.29-.22-.12-.94.39-1.6.51-.66,1.15-1.02,1.44-.8Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M75.89,31.87c.21.3-.13.9-.76,1.34-.63.44-1.31.55-1.52.25-.21-.3.13-.9.76-1.34.63-.44,1.31-.55,1.52-.25Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M85.73,39.61c-.27.25-.95-.05-1.51-.67-.56-.62-.8-1.32-.53-1.56.27-.25.95.05,1.51.67.56.62.8,1.32.53,1.56Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M89.47,41.29c-.14.34-.82.38-1.52.1-.7-.28-1.16-.79-1.02-1.13.14-.34.82-.38,1.52-.1.7.28,1.16.79,1.02,1.13Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M93.37,40.55c.06.36-.49.76-1.24.88-.74.13-1.4-.06-1.46-.42-.06-.36.49-.76,1.24-.88.74-.13,1.4.06,1.46.42Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M82.98,36.41c-.28.23-.94-.1-1.47-.74-.53-.64-.73-1.35-.45-1.59.28-.23.94.1,1.47.74.53.64.73,1.35.45,1.59Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M80.01,33.06c-.19.32-.87.26-1.52-.12-.65-.38-1.03-.95-.85-1.27.19-.32.87-.26,1.52.12.65.38,1.03.95.85,1.27Z"
                        ></path>
                        <path
                            style={{ fill: "#52508e", strokeWidth: "0px" }}
                            d="M89.01,27.58c-1.87.57-1.91,2.95-1.04,3.92-.23-.72-.34-1.29.08-2.09.68-1.29,2.74-1.78,4.57.55,2.95,3.76,2.19,10.32-2.53,9.05-5.76-1.55-4.86-11.54-11.77-13.02-6.91-1.48-10.09,5.16-14.06,8.32-3.67,2.93-5.56,1.82-6.7-1.06-.56-1.39.58-4.73,1.76-7.02,1.24-2.42,2.62-6.02,2.62-9.6,0-9.2-6.42-16.65-14.33-16.65s-14.33,7.45-14.33,16.65c0,2.42.42,4.38,1.24,6.79.82,2.41,1.73,4.58,1.86,5.67.58,4.94-2.35,6.95-6.4,6.66-4.87-.35-4.82-6.58-10.09-8.37-3.26-1.1-6.86-.07-9.17,1.66-2.84,2.14-4.41,5.88-4.15,7.6.23.07.56.15.96.17.03,0,.53.03,1.08-.08,2.13-.4,4.65-2.1,4.65-2.1.54-.36,1.27-.89,2.09-1.61,1.1-.3,2.08-.02,3.03,1.38,3.48,5.14,6.8,8,6.8,8-.35.42-.82,1.03-1.27,1.81-2.52,4.31-1.92,8.93-1.66,10.12,1.18,5.57,10.61,11.06,22.78,9.41-3.31-.37-8.14-1.47-12.45-4.97-.68-.55-1.29-1.11-1.84-1.68-1.65-3.29-1.63-5.61-1.39-6.95.03-.16.05-.27.06-.33.46-2.4,1.77-4.18,2.69-5.42.55-.75,1.07-1.33,1.46-1.74-.09.4-.19.98-.17,1.68.1,3.55,3.08,6.01,4.15,6.9,3.73,3.08,10.33,3.23,16.91.47-.05.32-.6-.28-.76,0-.91,1.49-3.65,2.19-5.55,2.67,0,0,0,0-.02,0-1.87.44-8.43,1.34-16.67-1.95-1.47-.59-3.55-1.53-5.91-3.02.41.86,1.01,1.96,1.84,3.15.25.35,1.17,1.64,2.78,3.25,0,0,2.23,2.22,4.49,3.69,5.5,3.57,23.4,3.14,26.26-5.06.41-1.18.79-3.32-.57-6.61,1.65-1.05,3.37-1.97,5.09-2.87.53-.28,1.1-.39,1.66-.35-.73-.92-1.32-1.46-1.32-1.46,0,0,3.07-3.55,7.13-8.79,5.38-6.95,9.5,1.63,12.02,4.2,4,4.08,9.72,3.85,10.56-1.9.74-5.06-2.42-10.32-6.44-9.09ZM49.76,49.85c-.44,0-5.94-.07-7.77-3.74-.41-.81-.53-1.6-.56-2.16.09-.35.54-1.99,2.17-3,.1-.06,1.88-1.12,3.93-.52,1.73.51,2.6,1.89,3.8,3.87,1.02,1.68,1.99,3.29,2.43,4.7-1.12.48-2.47.84-4,.85Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M46.84,29.08s-1.16-2.01-3.77-1.85c-2.53.15-2.56,2.61-2.56,2.61,0,0-.79-4.24,2.55-4.5,3.23-.25,3.79,3.74,3.79,3.74Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M47.87,29.01s.86-1.63,3.48-1.43c2.84.21,2.72,2.56,2.72,2.56,0,0,.53-3.89-2.79-4.31-3.4-.42-3.4,3.18-3.4,3.18Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M21.24,34.61c-2.29-2.12-2.44-2.92-3.84-3.49-1.63-.66-3.66-.5-5.3.43-2.88,1.63-3.2,4.87-3.22,5.15.15-.04.41-.11.73-.21,2-.65,3.52-1.69,4.03-2.07.38-.28.79-.52,1.17-.81.46-.36.77-.65,1.25-.68.22-.02.38.03.56.08,1.01.31,1.71,1.36,1.76,1.43,3.48,5.14,6.8,8,6.8,8,0,0,3.08-4.05-.29-4.94-1.34-.79-3.14-2.42-3.64-2.88Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M46.62,37.56c-.97-.04-1.77.28-1.89.33-2.47.99-3.18,3.86-3.28,4.28-.3,1.3-.12,2.34-.08,2.56.42,2.26,2.05,3.58,2.52,3.96,2.29,1.85,5.87,2.04,9.23.61-5.11,1.08-9.33-.39-10.34-2.96-.52-1.31-.49-3.62.94-4.92.08-.08,1.32-1.17,2.93-.92,1,.16,1.71.76,2.51,1.67,1.88,2.13,5.29,6.28,3.72,9.31-1.05,2.03-3.85,2.61-6.12,3.09-1.74.36-3.24.36-4.28.28,2.94.65,5.28.38,6.64.11,1.56-.31,3.17-.65,4.4-2.02.88-.98,1.18-2.04,1.3-2.49,1.35-5.08-3.74-12.7-8.2-12.87Z"
                        ></path>
                        <path
                            style={{ fill: "#91307d", strokeWidth: "0px" }}
                            d="M19.88,32.7v-.16s-.02-.02-.02,0v.16s.02.02.02,0h0Z"
                        ></path>
                        <path
                            style={{ fill: "#2d2d4f", strokeWidth: "0px" }}
                            d="M49.78,29.57c.64-.46,1.58-.4,2.08.26.29.38.94,0,.65-.38-.72-.96-2.1-1.25-3.1-.53-.39.28-.01.93.38.65h0Z"
                        ></path>
                        <path
                            style={{ fill: "#2d2d4f", strokeWidth: "0px" }}
                            d="M49.79,29.82c.53.04,1.05.08,1.58.12.34.03.74.09.98-.21.29-.38-.23-.91-.53-.53-.1.12-.81-.03-.98-.05l-1.05-.08c-.48-.04-.48.71,0,.75h0Z"
                        ></path>
                        <path
                            style={{ fill: "#2d2d4f", strokeWidth: "0px" }}
                            d="M44.42,29.27c-.63-.46-1.57-.4-2.06.26-.28.38-.93,0-.64-.38.71-.96,2.08-1.25,3.07-.53.38.28.01.93-.37.65h0Z"
                        ></path>
                        <path
                            style={{ fill: "#2d2d4f", strokeWidth: "0px" }}
                            d="M44.41,29.51l-1.56.12c-.34.03-.73.09-.97-.21-.29-.38.23-.91.52-.53.1.12.8-.03.97-.05l1.04-.08c.48-.04.47.71,0,.75h0Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M41.9,5.45c1.64-1.89,4.09-3.06,6.6-3.15,2.5-.09,5.03.91,6.8,2.68,2.08,2.09,3.05,5.1,3.12,8.05-1.69-2.88-4.69-4.82-7.88-5.8-3.19-.98-6.59-1.08-9.92-.96,1.6-2.9,5.07-4.65,8.35-4.2,3.28.44,6.16,3.05,6.93,6.27"
                        ></path>
                        <path
                            style={{ fill: "#9393da", strokeWidth: "0px" }}
                            d="M81.61,42.28c-.19-.18-.71-.67-1.56-1.12-3.53-1.88-8.18-1.74-11.35-1.35-.16,0-.17,0-.17,0h0s-.84,1-2.39,2.85c.42-.13,1.05-.3,1.84-.44,1.5-.27,4.06-.68,6.7.34,1.68.65,2.3,1.45,2.45,1.67.18.25,1.06,1.47.74,2.69-.45,1.68-2.95,2.6-4.83,2.32-1.59-.24-2.73-1.34-4.56-3.1-.62-.6-1.1-1.14-1.43-1.51-3.45-.43-6.74,3.22-6.74,3.22,5.34,7.33,12.83,10.5,17.88,8.35,2.11-.9,5.15-3.3,5.67-6.9.33-2.26-.32-5.14-2.26-7Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M60.31,47.84c.18.37.5.97.96,1.67,2.79,4.18,8.13,7.53,13.8,7.23.73-.04,1.81-.16,3.12-.54-6.4-1.28-9.85-3.83-11.83-6.03-1.58-1.75-3.43-4.61-7.38-6.77-1.35-.73-2.55-1.17-3.37-1.43.91.59,2.27,1.63,3.41,3.32.63.93,1.03,1.82,1.29,2.56Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M67.89,40.78c.28.23.09.94-.44,1.59-.53.65-1.18.98-1.47.75-.28-.23-.09-.94.44-1.59.53-.65,1.18-.98,1.47-.75Z"
                        ></path>
                        <path
                            style={{ fill: "#e7e514", strokeWidth: "0px" }}
                            d="M70.39,37.62c.29.22.12.94-.39,1.6-.51.66-1.15,1.02-1.44.8-.29-.22-.12-.94.39-1.6.51-.66,1.15-1.02,1.44-.8Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M94.11,39.85h0c-6.21,4.24-9.49-4.4-13.41-8.26-4.02-3.95-6.43-.74-11.45,4.29-5.02,5.02-8.17,4.62-8.17,4.62,2.61.94,4.65,2.66,4.65,2.66,0,0,3.07-3.55,7.13-8.79,5.38-6.95,9.5,1.63,12.02,4.2,3.05,3.11,7.1,3.72,9.23,1.28Z"
                        ></path>
                        <path
                            style={{ fill: "#7878d1", strokeWidth: "0px" }}
                            d="M33.54,59.55c-2.61-3.37-3.07-6.07-3.02-7.9.11-4.16,3.01-8.97,3.01-8.97h0s0,0,0,0h0s0,0,0,0c-.68.41-3.67,2.31-4.65,6.14-.08.29-1.24,5.18,1.93,8.71.95,1.06,1.99,1.67,2.73,2.02Z"
                        ></path>
                        <g>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M31.79,46.97c.34.15.31.9-.07,1.67-.38.78-.96,1.28-1.3,1.13-.34-.15-.31-.9.07-1.67.38-.78.96-1.28,1.3-1.14Z"
                            ></path>
                            <path
                                style={{ fill: "#e7e514", strokeWidth: "0px" }}
                                d="M31.04,53.09c.04-.21.05-.44.04-.69-.05-.84-.39-1.5-.77-1.46-.38.04-.65.75-.6,1.59.02.3.07.58.15.8.07.54.33.92.55.92.25,0,.59-.46.62-1.17Z"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};

export default Logo;
