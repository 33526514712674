import { configureStore } from "@reduxjs/toolkit";
import sitesReducer from "./reducers/sitesReducers";
import userReducer from "./slices/userReducer";
import alertReducer from "./reducers/alertReducers";

const store = configureStore({
    reducer: {
        sites: sitesReducer,
        user: userReducer,
        alert: alertReducer,
    },
});

export default store;