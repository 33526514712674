// src/components/Login.js
import React from "react";
import { useDispatch } from "react-redux";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import app from "../auth/firebase";
import { setActiveUser } from "../store/slices/userReducer.js";
import { GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, notification } from 'antd';
import Logo from '../images/logo.js';


function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    hd: 'maddenmedia.com'
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.error({
      message: 'Access Denied',
      description: 'Not permitted. Please contact support.',
    });
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        dispatch(setActiveUser({
          userName: result.user.displayName,
          userEmail: result.user.email,
          userID: result.user.uid
        }));
        navigate('/');
      })
      .catch((error) => {
        console.log(error.message);
        openNotification();
      });
  };

  return (
    <div className="login-container">
      {contextHolder}
      <div className="login-box">
        <Logo />
        <Divider />
        <Button onClick={handleGoogleSignIn} icon={<GoogleOutlined />}>Sign in with Google</Button>
      </div>
    </div>
  );
}

export default Login;
