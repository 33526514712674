// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBNa-mJAebM1sHOWIK4u6NA887k8to4FKE",
    authDomain: "mm-site-status.firebaseapp.com",
    projectId: "mm-site-status",
    storageBucket: "mm-site-status.appspot.com",
    messagingSenderId: "688820683525",
    appId: "1:688820683525:web:6222dadf3660cfb5086d6c",
    measurementId: "G-EDCQ1QM7H7"
  };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { firestore }

export default app