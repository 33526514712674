// src/pages/NotFound.js
import React, { useEffect, useRef, useState } from 'react';
import '../404.css';
import Logo from '../images/logo.js';

const NotFound = () => {
  const logoRef = useRef(null);
  const wrapperRef = useRef(null);
  const [bubbleCount, setBubbleCount] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      if (logoRef.current) {
        logoRef.current.style.transform = `translate(${clientX}px, ${clientY}px)`;
      }
    };

    const handleDeviceOrientation = (event) => {
      const { beta, gamma } = event; // beta: front-back tilt in degrees, gamma: left-right tilt in degrees
      const x = (window.innerWidth / 2) + (gamma * 10);
      const y = (window.innerHeight / 2) + (beta * 10);
      if (logoRef.current) {
        logoRef.current.style.transform = `translate(${x}px, ${y}px)`;
      }
    };

    const handleBubbleMouseOver = (event) => {
      if (event.target.classList.contains('bubble')) {
        event.target.remove();
        setBubbleCount((prevCount) => prevCount + 1);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('deviceorientation', handleDeviceOrientation);
    window.addEventListener('mouseover', handleBubbleMouseOver);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
      window.removeEventListener('mouseover', handleBubbleMouseOver);
    };
  }, []);

  useEffect(() => {
    const bArray = [];
    const sArray = [6, 8, 10, 12];

    for (let i = 0; i < window.innerWidth; i++) {
      bArray.push(i);
    }

    const randomValue = (arr) => arr[Math.floor(Math.random() * arr.length)];

    const interval = setInterval(() => {
      const size = randomValue(sArray);
      const bubble = document.createElement('div');
      bubble.className = 'bubble';
      bubble.style.left = `${randomValue(bArray)}px`;
      bubble.style.width = `${size}px`;
      bubble.style.height = `${size}px`;
      if (wrapperRef.current) {
        wrapperRef.current.appendChild(bubble);
      }

      bubble.animate(
        {
          bottom: '100%',
          opacity: '-=0.7',
        },
        {
          duration: 5000,
          easing: 'ease-out',
          iterations: 1,
          fill: 'forwards',
        }
      );

      setTimeout(() => {
        bubble.remove();
      }, 5000);
    }, 150);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (logoRef.current) {
      logoRef.current.style.width = `${100 + bubbleCount * 5}px`;
      logoRef.current.style.height = `${100 + bubbleCount * 5}px`;
    }
  }, [bubbleCount]);

  return (
    <div className="not-found-wrapper" ref={wrapperRef}>
      <div className="outer">
        <div className="inner">
          <h1>404</h1>
          <h2>Looks like we've hit a reef!</h2>
          <h3>This page is as elusive as the Kraken. Let's navigate back to familiar waters.</h3>
          <div className="logo-container" ref={logoRef}>
            <Logo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
