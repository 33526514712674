import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../store/actions/alertActions';

const AlertBanner = () => {
    const dispatch = useDispatch();
    const { message, type } = useSelector((state) => state.alert);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                dispatch(hideAlert());
            }, 5000);  // Auto hide after 5 seconds

            return () => clearTimeout(timer);  // Clear the timer if the component unmounts
        }
    }, [message, dispatch]);

    if (!message) return null;  // Don't render anything if there's no message

    return (
        <Alert
            message={message}
            type={type || 'info'}
            showIcon
            closable
            onClose={() => dispatch(hideAlert())}
            style={{ marginBottom: '16px' }}
        />
    );
};

export default AlertBanner;
