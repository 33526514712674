import axios from 'axios';
import { BASE_API_URL } from './constants';

export const updateProjectSetting = async (endpoint, payload) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/${endpoint}`, payload, {
        headers: {
          'X-Api-Key': 'mysecretkey',  // Replace with the actual API key
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating ${endpoint}:`, error);
      throw error;
    }
};
