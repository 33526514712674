let socket;

function getWebSocketUrl() {
  let protocol = 'wss'; // Default to secure WebSocket (wss)
  let port = window.location.port; // Use the same port as the current host

  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    protocol = 'ws'; // Use non-secure WebSocket (ws) for local development
  }

  if (window.location.port === '3000') {
    port = '8083'; // Replace with your WebSocket server port if different
  }

  return `${protocol}://${window.location.hostname}:${port}/ws`;
}

export function connectWebSocket(onMessage) {
  const wsUrl = getWebSocketUrl();
  socket = new WebSocket(wsUrl);

  socket.onopen = () => {
    console.log('WebSocket connected');
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (onMessage) {
      onMessage(data);
    }
  };

  socket.onclose = () => {
    console.log('WebSocket disconnected');
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
  };
}

export function sendMessage(message) {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify(message));
  } else {
    console.error('WebSocket is not open. Ready state is:', socket.readyState);
  }
}

export function closeWebSocket() {
  if (socket) {
    socket.close();
  }
}
