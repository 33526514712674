import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Table, Button, Input, Space } from 'antd';
import { SearchOutlined, LinkOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSites } from '../store/actions/sitesActions';
import { BASE_API_URL } from '../util/constants';

const getUniqueValues = (data, key) => {
  const uniqueValues = new Set(data.map(item => item[key]));
  return Array.from(uniqueValues).map(value => ({
    text: value,
    value,
  }));
};

const NavigateButton = ({ id }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/projects/${id}`);
  };

  return (
    <>
      <Button style={{ marginBottom: '0.5rem' }} type="default" href={`https://console.platform.sh/maddenmedia-com/${id}`} target="_blank" rel="noopener noreferrer">
        Platform.sh <LinkOutlined />
      </Button>
      <Button type="primary" onClick={handleClick}>
        View More Info
      </Button>
    </>
  );
};

const DashboardPage = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.sites.sites);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);  // Add state for filtered projects
  const searchInput = useRef(null);

  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, []);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_API_URL}/api/projects`);
      const projects = response.data;
      dispatch(updateSites(projects));
      setFilteredProjects(projects);  // Initialize the filtered projects with all projects
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  // New search function to filter the data
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    // Filter projects based on the search text
    const filteredData = projects.filter((project) =>
      project[dataIndex] ? project[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) : ''
    );
    setFilteredProjects(filteredData);  // Update the filtered projects
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilteredProjects(projects);  // Reset the filtered projects to the original dataset
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Project Title',
      dataIndex: 'project_title',
      key: 'project_title',
      ...getColumnSearchProps('project_title'),
      sorter: (a, b) => a.project_title.localeCompare(b.project_title),
      width: 150,
    },
    {
      title: 'Support Tier',
      dataIndex: 'support_tier',
      key: 'support_tier',
      filters: getUniqueValues(projects, 'support_tier'),
      onFilter: (value, record) => record.support_tier.includes(value),
      sorter: (a, b) => a.support_tier.localeCompare(b.support_tier),
    },
    {
      title: 'Region',
      dataIndex: 'project_region_label',
      key: 'project_region_label',
      filters: getUniqueValues(projects, 'project_region_label'),
      onFilter: (value, record) => record.project_region_label.includes(value),
      sorter: (a, b) => a.project_region_label.localeCompare(b.project_region_label),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      filters: getUniqueValues(projects, 'plan'),  // Keep all plan options including "development"
      onFilter: (value, record) => record.plan.includes(value),
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      filteredValue: getUniqueValues(projects, 'plan')
        .filter(plan => plan.value !== 'development')
        .map(plan => plan.value),  // Preselect all plans except "development"
    },
    {
      title: 'User Licenses',
      dataIndex: 'user_licenses',
      key: 'user_licenses',
      sorter: (a, b) => a.user_licenses - b.user_licenses,
    },
    {
      title: 'Storage (GB)',
      dataIndex: 'storage',
      key: 'storage',
      render: (text) => (text / 1024).toFixed(2),
      sorter: (a, b) => a.storage - b.storage,
    },
    {
      title: 'Max CPU',
      dataIndex: 'production_max_cpu',
      key: 'production_max_cpu',
      sorter: (a, b) => parseFloat(a.production_max_cpu) - parseFloat(b.production_max_cpu),
    },
    {
      title: 'Max Memory (GB)',
      dataIndex: 'production_max_memory',
      key: 'production_max_memory',
      render: (text) => (text / 1024).toFixed(2),
      sorter: (a, b) => a.production_max_memory - b.production_max_memory,
    },
    {
      title: 'Server Status',
      dataIndex: 'wp_plugin_api_key',
      key: 'wp_plugin_api_key',
      render: (bool) => bool ? 'Active' : 'Inactive',
      sorter: (a, b) => (a.wp_plugin_api_key || '').localeCompare(b.wp_plugin_api_key || ''),
    },
    {
      title: 'Actions',
      dataIndex: 'project_id',
      key: 'project_id',
      render: (text) => <NavigateButton id={text} />,
      fixed: 'right',
    },
  ];
  
  return (
    <div className="dashboard">
      <Table
        dataSource={filteredProjects}  // Use filteredProjects instead of projects
        columns={columns}
        sticky
        size="small"
        pagination={{
          pageSize: 12,
          showTotal: (total) => `Total ${total} items`
        }}
        style={{ padding: '1rem' }}
        scroll={{ y: 1200, x: 1200 }}
        rowKey="project_id"
        loading={loading}
      />
    </div>
  );
};

export default DashboardPage;
